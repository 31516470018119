import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Paper,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGetEmployeeInfoWithConfig } from "../api";
import EmployeeInfoForm from "../Components/EmployeeInfoForm";
import PrintableForm from "../Components/PrintableForm";

export default function EmployeePage(): ReactElement {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useState(0);
  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ackDate, setAckDate] = useState<Date>();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!id) return;

    const employeeInfoId: number = parseInt(id, 10);
    if (!employeeInfoId) return;

    setIsLoading(true);
    apiGetEmployeeInfoWithConfig(employeeInfoId)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");

        setYear(data.year);
        setEmpCode(data.empCode);
        setEmpName(data.empName);
        setEmpEmail(data.empEmail);
        setMessage(data.message);
        setAckDate(data.ackDate ? new Date(data.ackDate) : undefined);
        setChecked(Boolean(data.ackDate));
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, [id]);

  return (
    <AuthenticatedTemplate>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box displayPrint="none">
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Container maxWidth="md" style={{ marginTop: 40 }}>
          <Paper elevation={3}>
            <Box p={3}>
              <EmployeeInfoForm
                year={year}
                empCode={empCode}
                empName={empName}
                empEmail={empEmail}
                ackDate={ackDate}
              />
              <Box mt={1}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={message}
                  checked={checked}
                  disabled
                />
              </Box>
              <Box mt={2}>
                <Button variant="contained" onClick={() => window.print()}>
                  Print
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <Box display="none" displayPrint="contents">
        <PrintableForm
          year={year}
          empCode={empCode}
          empName={empName}
          empEmail={empEmail}
          ackDate={ackDate}
          checked={checked}
          message={message}
        />
      </Box>
    </AuthenticatedTemplate>
  );
}
