import { useIsAuthenticated } from "@azure/msal-react";
import { AccountCircle, Home, People, Settings } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiGetCurrentUser } from "../api";
import { CurrentUser } from "../types";

export default function Header(): ReactElement {
  const [userEmail, setUserEmail] = useState("");
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [latestConfigId, setLatestConfigId] = useState<number>();

  const isAuthenticated: boolean = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      apiGetCurrentUser()
        .then((data: CurrentUser) => {
          setUserEmail(data.email);
          setIsUserAdmin(data.isAdmin);
          setLatestConfigId(data.latestConfigId);
        })
        .catch(() => {
          setUserEmail("");
          setIsUserAdmin(false);
          setLatestConfigId(undefined);
        });
    }
  }, [isAuthenticated]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Box displayPrint="none">
      <AppBar
        position="static"
        elevation={3}
        style={{ backgroundColor: grey[50] }}
      >
        <Toolbar>
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/dm_logo.png"}
              alt="D&amp;M Logo"
              style={{
                height: "52px",
                verticalAlign: "middle",
                paddingRight: "2rem",
              }}
            />
          </Link>
          <Typography
            variant="subtitle2"
            color="inherit"
            noWrap
            style={{ color: grey[900], flexGrow: 1 }}
          >
            D&amp;M Bonus Acknowledgement
          </Typography>

          {isAuthenticated && (
            <>
              <Typography variant="subtitle2" style={{ color: grey[900] }}>
                {userEmail}
              </Typography>

              <IconButton onClick={openDrawer}>
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>

        <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
          <List style={{ width: 250 }}>
            <ListItem
              button
              component={Link}
              to="/"
              key="home"
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            {isUserAdmin && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/configs"
                  key="configs"
                  onClick={closeDrawer}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Configs" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to={
                    latestConfigId
                      ? "/employees?configId=" + latestConfigId
                      : "/employees"
                  }
                  key="employees"
                  onClick={closeDrawer}
                >
                  <ListItemIcon>
                    <People />
                  </ListItemIcon>
                  <ListItemText primary="Employees" />
                </ListItem>
              </>
            )}
          </List>
        </Drawer>
      </AppBar>
    </Box>
  );
}
