import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { ReactElement } from "react";

interface PrintableFormProps {
  year: number;
  empCode: string;
  empName: string;
  empEmail: string;
  ackDate: Date | undefined;
  checked: boolean;
  message: string;
}

export default function PrintableForm(props: PrintableFormProps): ReactElement {
  return (
    <div>
      <table style={{ margin: "auto" }}>
        <tbody>
          <tr>
            <th align="right">Year:</th>
            <td>&nbsp;</td>
            <td align="left">{props.year}</td>
          </tr>
          <tr>
            <th align="right">Employee Code:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empCode}</td>
          </tr>
          <tr>
            <th align="right">Employee Name:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empName}</td>
          </tr>
          <tr>
            <th align="right">Employee Email:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empEmail}</td>
          </tr>
          <tr>
            <th align="right">Acknowledgment Date:</th>
            <td>&nbsp;</td>
            <td align="left">{props.ackDate?.toLocaleString() || "-"}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: "2rem" }}>
        <div style={{ float: "left" }}>
          {props.checked ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </div>
        <div style={{ marginLeft: "2.5rem" }}>{props.message}</div>
      </div>
    </div>
  );
}
