import { Box } from "@mui/material";
import { ReactElement } from "react";
import appPackage from "../../package.json";

export default function Footer(): ReactElement {
  return (
    <Box
      display="flex"
      displayPrint="none"
      style={{ justifyContent: "center", fontSize: "0.8rem", padding: 15 }}
    >
      © DeGolyer and MacNaughton | Version {appPackage.version}
    </Box>
  );
}
