export interface CurrentUser {
  email: string;
  isAdmin: boolean;
  latestConfigId: number | undefined;
}

export interface GetAcknowledgmentResponse {
  configId: number;
  year: number;
  empCode: string;
  empName: string;
  empEmail: string;
  divName: string;
  message: string;
  isIncluded: boolean;
  ackDate: string;
  canSubmit: boolean;
}

export enum ConfigOrder {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  YEAR_ASC = "YEAR_ASC",
  YEAR_DESC = "YEAR_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
}

export interface ConfigData {
  year: number;
  startDate: string;
  endDate: string;
  message: string;
}

export interface Config extends ConfigData {
  id: number;
  canUpdate: boolean;
  canDelete: boolean;
}

export enum EmployeeInfoOrder {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  CONFIG_YEAR_ASC = "CONFIG_YEAR_ASC",
  CONFIG_YEAR_DESC = "CONFIG_YEAR_DESC",
  EMP_CODE_ASC = "EMP_CODE_ASC",
  EMP_CODE_DESC = "EMP_CODE_DESC",
  EMP_NAME_ASC = "EMP_NAME_ASC",
  EMP_NAME_DESC = "EMP_NAME_DESC",
  EMP_EMAIL_ASC = "EMP_EMAIL_ASC",
  EMP_EMAIL_DESC = "EMP_EMAIL_DESC",
  DIV_NAME_ASC = "DIV_NAME_ASC",
  DIV_NAME_DESC = "DIV_NAME_DESC",
  IS_INCLUDED_ASC = "IS_INCLUDED_ASC",
  IS_INCLUDED_DESC = "IS_INCLUDED_DESC",
  ACK_DATE_ASC = "ACK_DATE_ASC",
  ACK_DATE_DESC = "ACK_DATE_DESC",
}

export interface EmployeeInfo {
  id: number;
  year: number;
  empCode: string;
  empName: string;
  empEmail: string;
  divCode: string;
  divName: string;
  isIncluded: boolean;
  ackDate?: string;
  canUpdate: boolean;
}

export interface EmployeeInfoWithConfig extends EmployeeInfo {
  message: string;
}
