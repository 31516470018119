import { TextField } from "@mui/material";
import { ReactElement } from "react";

interface EmployeeInfoFormProps {
  year: number;
  empCode: string;
  empName: string;
  empEmail: string;
  ackDate: Date | undefined;
}

export default function EmployeeInfoForm(
  props: EmployeeInfoFormProps
): ReactElement {
  return (
    <>
      <TextField
        label="Year"
        value={props.year}
        variant="filled"
        size="small"
        margin="dense"
        fullWidth
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Employee Code"
        value={props.empCode}
        variant="filled"
        size="small"
        margin="dense"
        fullWidth
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Employee Name"
        value={props.empName}
        variant="filled"
        size="small"
        margin="dense"
        fullWidth
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Employee Email"
        value={props.empEmail}
        variant="filled"
        size="small"
        margin="dense"
        fullWidth
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Acknowledgment Date"
        value={props.ackDate?.toLocaleString() || "-"}
        variant="filled"
        size="small"
        margin="dense"
        fullWidth
        disabled
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
}
